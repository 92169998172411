import React from "react";
import {
  ContentSectionWrap,
} from "./content.stc";

const Content = ({ TextStyle, ProjectTypeHeading, ProjectTypeText }) => {
  return (
    <ContentSectionWrap>
      <main className="policy">
        <section className="policy__section l-container">
          <h1 className="policy__title">プライバシーポリシー</h1>
          <p className="align-R">
            制定日　2021年12月1日
            <br />
            最終改正日　2021年12月1日
            <br /><br />
          </p>
          <p className="policy__text">
            　株式会社Ｘ－ＨＡＣＫ（以下、「当社」という。）は、Webサービスの構築/導入支援事業、ITシステムの受託開発事業等を行っており、お客様、お取引先関係者、従業者、および関係者の個人情報保護が重大な責務であると認識しております。
            そこで個人情報保護理念と自ら定めた行動規範に基づき、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守致します。
            <br />
            　また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言致します。
          </p>
          <h2 className="policy__title2">個人情報保護方針</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              当社は、適切な個人情報の取得・利用及び提供を行い、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行ないません。また、そのための措置を講じます。
            </li>
            <li className="policy__item">
              当社は個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。
            </li>
            <li className="policy__item">
              当社は個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく、事業の実情に合致した経営資源を注入し、個人情報のセキュリティ体制を継続的に向上させていきます。
              また、万一の際には速やかに是正措置を講じます。
            </li>
            <li className="policy__item">
              当社は個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に対応致します。
            </li>
            <li className="policy__item">
              個人情報保護マネジメントシステムは、当社を取り巻く環境の変化と実情を踏まえ、適時・適切に見直して継続的に改善をはかります。
            </li>
          </ol>
          <p className="align-R">
            株式会社Ｘ－ＨＡＣＫ　　代表取締役　松田 信介
            <br />
            以上
          </p>

          <div className="policy__revision">
            <h2 className="policy__title2">【個人情報保護方針に関するお問い合わせ先 兼 個人情報に関する苦情・相談窓口】
            </h2>
            <p className="policy__text">
              　　　　〒141-0031　東京都品川区東五反田2-5-2 THE CASK GOTANDA（ザ・カスク五反田）702
              <br />
              　　　　株式会社Ｘ－ＨＡＣＫ　個人情報に関するお問い合わせ窓口
              <br />
              　　　　メールアドレス：smatsuda@x-hack.jp
            </p>
          </div>

          <h2 className="policy__title2">個人情報の利用目的の公表</h2>
          <p className="policy__text">
            　株式会社Ｘ－ＨＡＣＫ（以下、「当社」という。）は、個人情報を次の利用目的の範囲内で利用することを、個人情報の保護に関する法律（個人情報保護法）第18条第１項に基づき公表します。
          </p>
          <h3 className="infotmation__title3">個人情報の利用目的</h3>
          <p className="policy__text">
            当社は、次の利用目的で個人情報を取り扱います。
            <br />
            ・当社の製品やサービスに関する情報の提供のため
            <br />
            ・製品やサービスのアフターサービスを適切に提供するため
            <br />
            ・当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするため
            <br />
            ・当社の従業者の個人情報は、人事労務管理、業務管理、健康管理等のため
            <br />
            ・採用業務、応募者との連絡のため
          </p>
          <p className="align-R">
            以上
          </p>

          <h2 className="policy__title2">
            保有個人データに関する事項の周知について
          </h2>
          <p className="policy__text">
            　株式会社Ｘ－ＨＡＣＫ（以下、「当社」という。）の保有個人データ（当社が、利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止を行う権限を有するもの）は、以下の通りです。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              個人情報に関する管理者の氏名、所属及び連絡先
              <p className="policy__text">
              管理者名：個人情報保護管理者　松田 信介
              <br />
              メールアドレス：smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              保有個人データの利用目的
              <p className="policy__text">
              ・当社の製品やサービスに関する情報の提供のため
              <br />
              ・製品やサービスのアフターサービスを適切に提供するため
              <br />
              ・当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするため
              <br />
              ・当社の従業者の個人情報は、人事労務管理、業務管理、健康管理等のため
              <br />
              ・採用業務、応募者との連絡のため
              </p>
            </li>
            <li className="policy__item">
              保有個人データに関する苦情の申し出先
              <p className="policy__text">
                保有個人データに関するお問合せにつきましては、下記窓口で受付けております。
                <br />
                　　　　〒141-0022　東京都品川区東五反田2-5-2 THE CASK GOTANDA（ザ・カスク五反田）702
                <br />
                　　　　株式会社Ｘ－ＨＡＣＫ　個人情報に関するお問い合わせ窓口
                <br />
                　　　　メールアドレス：smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              当社の加入する認定個人情報保護団体について
              <p className="policy__text">
                当社の加入する認定個人情報保護団体はありません。
              </p>
            </li>
          </ol>

          <h2 className="policy__title2">
            保有個人データの開示等に関する手続き
          </h2>
          <p className="policy__text">
            　保有個人データについて、「利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止」（開示等）のご請求が出来ます。
            <br />
            　当社では、次の手順で個人情報の開示等の求めに対応いたします。
          </p>

          <ol className="policy__olist">
            <li className="policy__item">
              開示等の求めの受付
              <p className="policy__text">
              （１）下記の宛先までご連絡ください。
              <br />
              当社より「所定の用紙」を郵送いたしますので、到着後、当社の「個人情報の取扱いについて」に同意後、以下の宛先まで郵送またはご持参ください。
              <br />
              　　　　〒141-0022　京都品川区東五反田2-5-2 THE CASK GOTANDA（ザ・カスク五反田）702
              <br />
              　　　　株式会社Ｘ－ＨＡＣＫ　個人情報に関するお問い合わせ窓口
              <br />
              　　　　メールアドレス：smatsuda@x-hack.jp
              <br />
              <br />
              （２）本人確認のため、以下の書類いずれか1通を(1)「所定の用紙」とともに郵送またはご持参ください。
              <br />
              ・運転免許証、パスポート等の公的証明書で本人確認ができるものの写し（開示等の求めをする本人の名前および住所が記載されているもの）
              <br />
              ・住民票の写し（開示等の求めをする日前３０日以内に作成されたもの）
              <br />
              <br />
              （３）代理人の方が手続きをされる場合は、（１）「所定の用紙」、（２）「本人確認書類」に加え、以下のア）のいずれか一つおよびイ）の書類も郵送またはご持参ください。
              <br />
              ア)代理人の本人確認書類
              <br />
              ・代理人を証明する書類、代理人の運転免許証、パスポート等の公的証明書で代理人確認ができるものの写し（開示等の求めをする代理人の名前および住所が記載されているもの）
              <br />
              ・代理人の住民票の写し（開示等の求めをする日前３０日以内に作成されたもの）
              <br />
              ・代理人が弁護士の場合は、登録番号のわかる書類
              <br />
              イ）代理権を証する書面（委任状など）
              <br />
              ・本人または代理人を証明する書類に、本籍地が明示されている場合は、消去していただいて結構です。
              <br />
              ・本人または代理人を証明する書類は、当社が入手してから2か月以内に責任を持って廃棄いたします。
              </p>
            </li>
            <li className="policy__item">
              「開示等の請求」の手数料及びその徴収方法
              <p className="policy__text">
                利用目的の通知又は開示の請求の場合にのみ、１回の請求につき、以下の金額（当社からの返信費を含む）を申し受けます。
                下記金額分の郵便定額小為替を請求書類の郵送時に同封してください。
                <br />
                ・手数料金額：1,000円（税込）
              </p>
            </li>
            <li className="policy__item">
              検討結果連絡
              <p className="policy__text">
                当社内で検討した結果は、以下の方法により遅滞なくご連絡します。
                <br />
                ・個人情報の利用目的の通知、開示の場合は、書面の郵送または手渡し
                <br />
                ・個人情報の内容の訂正、通知または削除、利用の停止、消去および第三者への提供の停止の場合は、電話またはメール
                <br />
                <br />
                書面の送付先は、以下のいずれかの適切な方法を選択し行います。
                <br />
                ・登録されている本人住所に回答文面を郵送する。
                <br />
                ・登録されている本人のＥメールアドレスに回答文面をメールする。
                <br />
                ・登録されている本人の電話番号に電話をかけ、口頭にて回答する。
              </p>
            </li>
          </ol>
          <p className="align-R">
            以上
          </p>

          <h2 className="policy__title2" id="privacy_policy">個人情報の取扱いについて</h2>
          <p className="policy__text">
            　当社は、JIS Q 15001:2017のA.3.4.2.5（A.3.4.2.4のうち本人から直接書面によって取得する場合の措置）に従い、個人情報を収集・保管いたします。
            <br />
            　この入力フォームで取得する個人情報の取り扱いは下記3項の利用目的のためであり、この目的の範囲を超えて利用することはございません。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              事業者名
              <p className="policy__text">
              株式会社Ｘ－ＨＡＣＫ
              </p>
            </li>
            <li className="policy__item">
              個人情報に関する管理者の氏名、所属及び連絡先
              <p className="policy__text">
                個人情報保護管理者：　松田 信介
                <br />
                メールアドレス：smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              個人情報の利用目的
              <p className="policy__text">
                ・お問合せにお答えするため
              </p>
            </li>
            <li className="policy__item">
              個人情報の第三者提供
              <p className="policy__text">
                当社は、ご提供いただいた個人情報を次の場合を除き第三者に開示・提供いたしません。
                <br />
                ・ご本人の同意がある場合
                <br />
                ・法令に基づく場合
                <br />
                ・人の生命、身体又は財産の保護のために必要がある場合であって、人の同意を得ることが困難であるとき
                <br />
                ・公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって本人の、同意を得ることが困難であるとき
                <br />
                ・国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき
              </p>
            </li>
            <li className="policy__item">
              個人情報取扱いの委託
              <p className="policy__text">
                当社は、ご提供いただいた個人情報の取扱いを委託することはありません。
              </p>
            </li>
            <li className="policy__item">
              個人情報の開示等の請求
              <p className="policy__text">
                お客様が当社に対してご自身の個人情報の開示等（利用目的の通知、開示、内容の訂正・追加・削除、利用の停止または消去、第三者への提供の停止）に関して、当社「個人情報に関するお問合わせ窓口」に申し出ることができます。
                その際、当社はご本人を確認させていただいたうえで、合理的な期間内に対応いたします。開示等の申し出の詳細につきましては、下記の「個人情報に関するお問い合わせ窓口」までお問い合わせください。
              <br />
              <br />
              　　　　〒141-002　東京都品川区東五反田2-5-2 THE CASK GOTANDA（ザ・カスク五反田）702
              <br />
              　　　　株式会社Ｘ－ＨＡＣＫ　個人情報に関するお問い合わせ窓口
              <br />
              　　　　メールアドレス：smatsuda@x-hack.jp              
              </p>
            </li>
            <li className="policy__item">
              個人情報を提供されることの任意性について
              <p className="policy__text">
                お客様が当社に個人情報を提供されるかどうかは、お客様の任意によるものです。
                ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。
              </p>
            </li>
            <li className="policy__item">
              本Webサイトへアクセスしたことを契機として機械的に取得される情報
              <p className="policy__text">
                当社は、閲覧されたWebサイトのセキュリティ確保・ユーザーサービス向上のため、Cookieにより閲覧された方の情報を取得することがあります。
              </p>
            </li>
          </ol>
          <p className="align-R">
            以上
          </p>
        </section>
      </main>
    </ContentSectionWrap>
  )
}

export default Content